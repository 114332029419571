import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "@/store";
import axios from "axios";
import VueAxios from "vue-axios";
import i18n from "@/plugins/i18n.js";
import "./assets/styles/app.css";
// Globally register all `_base`-prefixed components
import "@/components/global/_globals.js";
import "@/api/Amplify/amplifyClient.js";
import "@/filters";
try {
  window.localStorage.setItem("gol.test-ls", "1");
  window.localStorage.removeItem("gol.test-ls");
  // we know local storage is working
} catch (error) {
  console.log(error.stack);
  console.warn("Cannot use localstorage; clearing");
  // return MemoryStorage;
  window.localStorage.clear();
}

const {
  VUE_APP_MAIN_ADMIN_API,
  VUE_APP_GOOGLE_MEASUREMENT_ID,
  VUE_APP_IUBENDA_SITE_ID,
  VUE_APP_IUBENDA_COOKIE_POLICY_ID,
  VUE_APP_GTM_CODE
} = process.env;

Vue.config.productionTip = false;
// load layout
import LayoutEmpty from "./layouts/LayoutEmpty.vue";
Vue.component("LayoutEmpty", LayoutEmpty);
import FormLayout from "./layouts/FormLayout.vue";
Vue.component("FormLayout", FormLayout);
import LayoutDefault from "./layouts/LayoutDefault.vue";
Vue.component("LayoutDefault", LayoutDefault);
// for development load the components manually so it does not break with the devtool open
//https://github.com/vuejs/vue-devtools/issues/944 - vue-devtools issue
import LayoutLogged from "./layouts/LayoutLogged.vue";
Vue.component("LayoutLogged", LayoutLogged);
import LayoutLoggedEmpty from "./layouts/LayoutLoggedEmpty.vue";
Vue.component("LayoutLoggedEmpty", LayoutLoggedEmpty);
// end layout

import { createProvider } from "./vue-apollo";

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css"; // or theme-sugar
Vue.use(VueToast);

import VueGtag, { setOptions, bootstrap } from "vue-gtag";
if (VUE_APP_GOOGLE_MEASUREMENT_ID && VUE_APP_GOOGLE_MEASUREMENT_ID !== "0") {
  Vue.use(VueGtag, {
    bootstrap: false
  }); // VueGtag should not be given the router here, gtag.js tracks route transitions itself
}

// Iubenda
import VueIubendaCookieConsent from "@/plugins/vue-iubenda-cookie-consent";
if (
  VUE_APP_IUBENDA_SITE_ID &&
  VUE_APP_IUBENDA_COOKIE_POLICY_ID &&
  ((VUE_APP_GOOGLE_MEASUREMENT_ID && VUE_APP_GOOGLE_MEASUREMENT_ID !== "0") ||
    (VUE_APP_GTM_CODE && VUE_APP_GTM_CODE !== "0"))
) {
  Vue.use(VueIubendaCookieConsent, {
    lang: "en",
    siteId: VUE_APP_IUBENDA_SITE_ID,
    cookiePolicyId: VUE_APP_IUBENDA_COOKIE_POLICY_ID,
    content:
      "<div id=\"iubenda-cs-title\">Notice</div><div id=\"iubenda-cs-paragraph\"><p class=\"iub-p\">We and selected partners use cookies or similar technologies for technical purposes and with your consent, for additional supporting purposes ('basic interactions & functionalities', 'experience enhancement' & 'measurement') as specified in our cookie policy.  You can consent to the use of such technologies by using the 'Accept' button.</p><br/></div>",
    customizeButtonCaption: "Learn more",
    callback: {
      onConsentGiven: () => {
        console.log("iubenda:onConsentGiven");
        if (
          VUE_APP_GOOGLE_MEASUREMENT_ID &&
          VUE_APP_GOOGLE_MEASUREMENT_ID !== "0"
        ) {
          setOptions({
            enabled: true,
            config: {
              id: VUE_APP_GOOGLE_MEASUREMENT_ID
            }
          });
          window["ga-disable-" + VUE_APP_GOOGLE_MEASUREMENT_ID] = false;
          bootstrap();
        }
      },
      onConsentRejected: () => {
        console.log("iubenda:onConsentRejected");
        if (
          VUE_APP_GOOGLE_MEASUREMENT_ID &&
          VUE_APP_GOOGLE_MEASUREMENT_ID !== "0"
        ) {
          setOptions({
            enabled: false,
            config: {
              id: VUE_APP_GOOGLE_MEASUREMENT_ID
            }
          });
          window["ga-disable-" + VUE_APP_GOOGLE_MEASUREMENT_ID] = true;
          this.$gtag.optOut();
        }
      },
      onPreferenceExpressedOrNotNeeded: function(preference) {
        if (VUE_APP_GTM_CODE && VUE_APP_GTM_CODE !== "0") {
          window.dataLayer.push({
            iubenda_ccpa_opted_out: window._iub.cs.api.isCcpaOptedOut()
          });
          if (!preference) {
            window.dataLayer.push({
              event: "iubenda_preference_not_needed",
              iubenda_status: "iubenda_preference_not_needed"
            });
          } else {
            if (preference.consent === true) {
              window.dataLayer.push({
                event: "iubenda_consent_given",
                iubenda_status: "iubenda_consent_given"
              });
            } else if (preference.consent === false) {
              window.dataLayer.push({
                event: "iubenda_consent_rejected",
                iubenda_status: "iubenda_consent_rejected"
              });
            } else if (preference.purposes) {
              for (var purposeId in preference.purposes) {
                if (preference.purposes[purposeId]) {
                  window.dataLayer.push({
                    event: "iubenda_consent_given_purpose_" + purposeId,
                    iubenda_status: "iubenda_consent_given_purpose_" + purposeId
                  });
                }
              }
            }
          }
        }
      }
    }
  });
}

// make a single analytics class accessible to everything
import Analytics from "@/api/analytics";
Vue.use(Analytics);

// send amplify authentication events to gtag via Analytics
import HubListener from "@/api/Amplify/hubListener";
Vue.use(HubListener);

import VueHead from "vue-head";

Vue.use(VueHead);

//Custom settings file
const system_config = require("@/constants/config.json");
Vue.prototype.$system_config = system_config;

// Axios
axios.defaults.baseURL = VUE_APP_MAIN_ADMIN_API;
Vue.use(VueAxios, axios);

import { FeatureToggleComponent as feature } from "vue-feature-toggle";

const { VUE_APP_ENABLED_FEATURES } = process.env;
const features = (VUE_APP_ENABLED_FEATURES || "").split(",");
for (let featureName of features) {
  feature.visibility(featureName, true);
}

new Vue({
  router,
  store,
  i18n,
  apolloProvider: createProvider(),
  render: h => h(App)
}).$mount("#app");
