import router from "../router";
import store from "@/store";

export default function apolloErrorHandling(graphQLErrors) {
  if (
    graphQLErrors.some(error => error.errorType === "Unauthorized") &&
    !store.getters["auth/isLogged"]
  ) {
    router.push({
      name: "SignInPage",
      query: { returnURL: router.history.current.fullPath }
    });
    return "redirected";
  } else {
    return "unhandled";
  }
}
