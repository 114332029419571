export const euro = {
  code: "eur",
  name: "EUR",
  htmlEntity: "&euro;",
  symbol: "€",
  pointsPerUnit: {
    value: 2,
    short: "2",
    long: "2.00"
  }
};

export const pound = {
  code: "gbp",
  name: "GBP",
  htmlEntity: "&pound;",
  symbol: "£",
  pointsPerUnit: {
    value: 2,
    short: "2",
    long: "2.00"
  }
};

export const usd = {
  code: "usd",
  name: "USD",
  htmlEntity: "&#36;",
  symbol: "$",
  pointsPerUnit: {
    value: 2,
    short: "2",
    long: "2.00"
  }
};

export default usd;
