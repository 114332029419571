<template>
  <div class="w-full grid grid-cols-2 gap-2">
    <router-link
      class="btn btn-clear btn-thin"
      :to="{ name: 'SignInPage', query: { returnURL: returnUrl } }"
      >{{ $t("message.log-in") }}</router-link
    >
    <router-link
      class="btn btn-default btn-thin"
      :to="{ name: 'SignUpEmailForm', query: { returnURL: returnUrl } }"
      >{{ $t("message.get-started") }}</router-link
    >
  </div>
</template>
<script>
export default {
  computed: {
    returnUrl() {
      return this.$route.fullPath;
    }
  }
};
</script>
